var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "login-page" } }, [
    _c("div", { staticClass: "page-title" }, [_vm._v(" 非APP环境 请登录 ")]),
    _c(
      "div",
      {
        staticClass: "login-form",
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.loginIntoApp.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "van-cell-group",
          [
            _c("van-field", {
              attrs: {
                required: "",
                clearable: "",
                label: "用户名",
                placeholder: "请输入用户名",
              },
              model: {
                value: _vm.username,
                callback: function ($$v) {
                  _vm.username = $$v
                },
                expression: "username",
              },
            }),
            _c("van-field", {
              attrs: {
                type: "password",
                label: "密码",
                placeholder: "请输入密码",
                required: "",
              },
              model: {
                value: _vm.password,
                callback: function ($$v) {
                  _vm.password = $$v
                },
                expression: "password",
              },
            }),
          ],
          1
        ),
        _c(
          "van-button",
          {
            staticStyle: { "margin-top": "20px" },
            attrs: { type: "primary", hairline: "", plain: "", block: "" },
            on: { click: _vm.loginIntoApp },
          },
          [
            _vm.isLoading
              ? _c(
                  "span",
                  { staticClass: "login-hint" },
                  [_c("van-loading", { attrs: { color: "black" } })],
                  1
                )
              : _c("span", [_vm._v("登录")]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }