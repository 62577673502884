<template>
  <div id="login-page">
    <div class="page-title">
      非APP环境 请登录
    </div>
    <div class="login-form" @keyup.enter="loginIntoApp">
      <van-cell-group>
        <van-field
          v-model="username"
          required
          clearable
          label="用户名"
          placeholder="请输入用户名"
        />

        <van-field
          v-model="password"
          type="password"
          label="密码"
          placeholder="请输入密码"
          required
        />
      </van-cell-group>
      <van-button
        type="primary"
        style="margin-top:20px"
        hairline
        plain
        block
        @click="loginIntoApp"
      >
        <span v-if="isLoading" class="login-hint">
          <van-loading color="black" />
        </span>
        <span v-else>登录</span>
      </van-button>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import { caseBase } from '~api-config'
export default {
  name: 'login',
  data() {
    return {
      username: '',
      password: '',
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('user', ['setIsLogin', 'setToken', 'setUserInfo']),
    loginIntoApp() {
      this.isLoading = true
      const account = this.username
      const password = this.password
      const loginUrl = `${caseBase}/farbun/user/appSignin?account=${account}&password=${password}&rememberMe=0`
      this.$axios({
        url: loginUrl,
        method: 'POST',
        headers: {
          'Terminal-Type': 'ANDROID'
        }
      })
        .then(async ({ data: { code, message, data } }) => {
          if (code === 200) {
            this.isLoading = false
            this.setIsLogin(true)
            this.setToken(data.token.token)
            this.setUserInfo(data.user)
            //  登录成功提示
            this.$notify({
              message: '登录成功',
              duration: 1000,
              background: '#1989fa'
            })
            const res = await this.$axios.get(
              `${caseBase}/management/users/${data.user.id}`
            )
            let userInfo = data.user
            if (res.data.code === 200) {
              userInfo = {
                ...userInfo,
                ...res.data.data
              }
            }
            // 将用户信息存储到 sessionStorage
            const ss = window.sessionStorage
            ss.setItem('token', data.token.token)
            ss.setItem('userInfo', JSON.stringify(userInfo))

            // 跳转到首页
            setTimeout(() => {
              this.$router.push({ path: '/' })
            }, 1000)
          } else {
            this.isLoading = false
            this.$notify(message)
          }
        })
        .catch(err => {
          this.isLoading = false
          this.$notify(err)
          Promise.reject(err)
        })
    }
  }
}
</script>

<style lang="stylus" scpoed>
#login-page
  display flex
  align-items center
  justify-content center
  width 100%
  height 100%
  position fixed
  top 0
  left 0
  background #fafafa
  flex-direction column
  .page-title
    margin -60px 0 0 0
    font-weight 400
    font-size 14px
    color #444
    padding 40px 15px 15px
  .login-form
    width 100%
.login-hint
  display flex
  align-items center
  .van-loading
    margin 0 auto
</style>
